import React, {useCallback, useState} from 'react';
import Typography from '@tiptap/extension-typography';
import {EditorContent, useEditor} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';

import {AnimatePresence, motion} from 'framer-motion';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import Image from '@tiptap/extension-image';
import 'tippy.js/animations/scale.css';
import FontFamily from '@tiptap/extension-font-family';
import {TextStyle} from '@tiptap/extension-text-style';
import {TextAlign} from '@tiptap/extension-text-align';
import Slash from './extensions/Slash';
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Bold,
  Code,
  Heading1,
  Heading2,
  Heading3,
  Italic,
  LinkIcon,
  List,
  Strikethrough,
  TextQuote,
} from 'lucide-react';
import {Dropcursor} from '@tiptap/extension-dropcursor';
import {EditorBubbleMenu} from './extensions/EditorBubbleMenu';
import {Color} from '@tiptap/extension-color';
import {Modal} from '../Modal';

export interface MarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
}

/**
 *
 * @param root0
 * @param root0.value
 * @param root0.onChange
 */
export default function Editor({value, onChange}: MarkdownEditorProps) {
  const [urlModal, setUrlModal] = useState(false);

  const editor = useEditor({
    extensions: [
      Slash,
      StarterKit,
      Typography,
      TextStyle,
      Dropcursor.configure({
        width: 3,
        color: '#e5e5e5',
      }),
      TextAlign.configure({
        alignments: ['left', 'center', 'right'],
        types: ['heading', 'paragraph'],
        defaultAlignment: 'left',
      }),
      FontFamily.configure({
        types: ['textStyle'],
      }),
      Image.configure({allowBase64: true}),
      Placeholder.configure({
        placeholder: 'Start typing or press / to use a slash command',
        includeChildren: true,
      }),

      Link.configure({
        autolink: true,
        protocols: ['http', 'https', 'mailto'],
      }).extend({
        addKeyboardShortcuts() {
          return {
            Space: ({editor}) => {
              if (editor.isActive('link')) {
                // Toggle the link and add a space
                editor.commands.toggleMark('link');
                // Add a space
                return editor.chain().focus().insertContent(' ').run();
              }

              return false;
            },
          };
        },
      }),
      Color,
    ],
    content: value,
    editorProps: {
      attributes: {
        class: `prose font-sans my-5 focus:outline-none`,
      },
      handleDOMEvents: {
        keydown: (_view, event) => {
          return event.key === 'Enter' && !event.shiftKey;
        },
        paste: (view, event) => {
          const text = event.clipboardData?.getData('text');
          const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*({{[\w-]+}})?)*$/;

          if (editor && text && urlPattern.test(text)) {
            event.preventDefault();
            editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({href: text.startsWith('http') ? text : `https://${text}`, target: '_blank'})
              .run();
            editor.commands.insertContent(text);
          }
          return true;
        },
      },
    },
    onUpdate: ({editor}) => {
      onChange(editor.getHTML());
    },
  });

  const {
    register: registerUrl,
    handleSubmit: handleSubmitUrl,
    reset: resetUrl,
    setFocus: setFocusUrl,
    formState: {errors: errorsUrl},
  } = useForm<{
    url: string;
  }>({
    resolver: zodResolver(
      z.object({
        url: z
          .string()
          .regex(/^(?:(?:https?):\/\/)?(?:\{\{[\w-]+\}\}|(?:[\w-]+\.)+[a-z]{2,})(?:\/[^\s]*)?$/)
          .transform(u => (u.startsWith('http') ? u : `https://${u}`)),
      }),
    ),
  });

  const addUrl = useCallback(
    (data: {url: string}) => {
      editor?.chain().focus().setLink({href: data.url, target: '_blank'}).run();
      setUrlModal(false);
      resetUrl();
    },
    [editor],
  );

  if (!editor) {
    return null;
  }

  return (
    <>
      <Modal
        title={'Add URL'}
        description={'Copy and paste the URL'}
        isOpen={urlModal}
        onToggle={() => setUrlModal(!urlModal)}
        onAction={handleSubmitUrl(addUrl)}
        type={'info'}
        action={'Add'}
      >
        <form onSubmit={handleSubmitUrl(addUrl)} className="grid gap-6 sm:grid-cols-2">
          <div className={'sm:col-span-2'}>
            <label htmlFor={'url'} className="block text-sm font-medium text-neutral-700">
              URL
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center rounded-l border border-r-0 border-neutral-300 bg-neutral-50 px-3 text-neutral-500 sm:text-sm">
                https://
              </span>
              <input
                type="text"
                autoComplete={'off'}
                className={
                  'block w-full rounded-r border-neutral-300 transition ease-in-out focus:border-neutral-900 focus:ring-neutral-900 sm:text-sm'
                }
                placeholder="www.example.com"
                {...registerUrl('url')}
              />
            </div>
            <AnimatePresence>
              {errorsUrl.url?.message && (
                <motion.p
                  initial={{height: 0}}
                  animate={{height: 'auto'}}
                  exit={{height: 0}}
                  className="mt-1 text-xs text-red-500"
                >
                  {errorsUrl.url.message}
                </motion.p>
              )}
            </AnimatePresence>
          </div>
        </form>
      </Modal>

      <div className={'flex w-full flex-col items-center rounded-xl border border-neutral-300 p-6'}>
        <div className={'grid gap-3 sm:grid-cols-2'}>
          <div className={'flex'}>
            <button
              name={'Main heading'}
              title={'Main heading'}
              className={`flex items-center justify-center rounded-l-md border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().toggleHeading({level: 1}).focus().run();
              }}
            >
              <Heading1 size={24} strokeWidth={editor.isActive('heading', {level: 1}) ? '2.5' : '1.5'} />
            </button>
            <button
              name={'Heading 2'}
              title={'Heading 2'}
              className={`flex items-center justify-center border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().toggleHeading({level: 2}).focus().run();
              }}
            >
              <Heading2 size={24} strokeWidth={editor.isActive('heading', {level: 2}) ? '2.5' : '1.5'} />
            </button>
            <button
              name={'Heading 3'}
              title={'Heading 3'}
              className={`flex items-center justify-center rounded-r-md border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().toggleHeading({level: 3}).focus().run();
              }}
            >
              <Heading3 size={24} strokeWidth={editor.isActive('heading', {level: 3}) ? '2.5' : '1.5'} />
            </button>
          </div>
          <div className={'flex'}>
            <button
              name={'Align Left'}
              title={'Align Left'}
              className={`flex items-center justify-center rounded-l-md border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().setTextAlign('left').run();
              }}
            >
              <AlignLeft size={24} strokeWidth={editor.isActive('textAlign', {textAlign: 'left'}) ? '2.5' : '1.5'} />
            </button>
            <button
              name={'Align Center'}
              title={'Align Center'}
              className={`flex items-center justify-center border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().setTextAlign('center').run();
              }}
            >
              <AlignCenter
                size={24}
                strokeWidth={editor.isActive('textAlign', {textAlign: 'center'}) ? '2.5' : '1.5'}
              />
            </button>
            <button
              name={'Align Right'}
              title={'Align Right'}
              className={`flex items-center justify-center rounded-r-md border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().setTextAlign('right').run();
              }}
            >
              <AlignRight size={24} strokeWidth={editor.isActive('textAlign', {textAlign: 'right'}) ? '2.5' : '1.5'} />
            </button>
          </div>
          <div className={'flex'}>
            <button
              name={'Bold'}
              title={'Bold'}
              className={`flex items-center justify-center rounded-l-md border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().toggleBold().run();
              }}
            >
              <Bold size={24} strokeWidth={editor.isActive('bold') ? '2.5' : '1.5'} />
            </button>
            <button
              name={'Italic'}
              title={'Italic'}
              className={`flex items-center justify-center border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().toggleItalic().run();
              }}
            >
              <Italic size={24} strokeWidth={editor.isActive('italic') ? '2.5' : '1.5'} />
            </button>
            <button
              name={'Strikethrough'}
              title={'Strikethrough'}
              className={`flex items-center justify-center rounded-r-md border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().toggleStrike().run();
              }}
            >
              <Strikethrough size={24} strokeWidth={editor.isActive('strike') ? '2.5' : '1.5'} />
            </button>
          </div>

          <div className={'flex'}>
            <button
              name={'Code Block'}
              title={'Code Block'}
              className={`flex items-center justify-center rounded-l-md border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().toggleCodeBlock().run();
              }}
            >
              <Code size={24} strokeWidth={editor.isActive('codeBlock') ? '2.5' : '1.5'} />
            </button>
            <button
              name={'Blockquote'}
              title={'Blockquote'}
              className={`flex items-center justify-center border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().toggleBlockquote().run();
              }}
            >
              <TextQuote size={24} strokeWidth={editor.isActive('blockquote') ? '2.5' : '1.5'} />
            </button>
            <button
              name={'Bullet List'}
              title={'Bullet List'}
              className={`flex items-center justify-center rounded-r-md border border-neutral-300 px-3 py-1 text-neutral-800 transition ease-in-out hover:bg-neutral-50`}
              onClick={e => {
                e.preventDefault();
                editor.chain().focus().toggleBulletList().run();
              }}
            >
              <List size={24} strokeWidth={editor.isActive('bulletList') ? '2.5' : '1.5'} />
            </button>
          </div>
        </div>
        <>
          <div className={'prose prose-sm prose-neutral w-full break-words px-6 py-3 sm:px-0'}>
            <EditorContent editor={editor} className={'cursor-pointer'} />
            <EditorBubbleMenu
              editor={editor}
              items={[
                {
                  name: 'Link',
                  icon: LinkIcon,
                  command: () => {
                    setUrlModal(true);
                    setTimeout(() => {
                      setFocusUrl('url', {shouldSelect: true});
                    }, 100);
                  },
                  isActive: () => false,
                },
              ]}
            />
          </div>
        </>
      </div>
    </>
  );
}
